<template>
    <div class="home">
        <div class="container citr-add-bar">
            <div class="row justify-content-md-center">
                <div class="col-8">
                    <div class="input-group input-group-lg mb-3">
                        <span class="input-group-text" id="add-pubmed-label">Pubmed</span>
                        <input type="text" class="form-control form-control-lg" placeholder="Id" aria-label="Pubmed Id" aria-describedby="add-pubmed-label" v-model="input">
                        <button type="button" class="btn btn-primary" @click="fetchPaper">Add</button>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="container-fluid">
            <div class="row">
                <div class="col d-flex align-items-stretch flex-wrap justify-content-start">
                    <template v-for="paper in papers" :key="paper.pmid">
                        <citr-paper :paper="paper" @click="changeSelection(paper)" :class="{'is-selected': paper.pmid == selected}" />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Paper from '@/components/Paper'
import ncbi from 'node-ncbi'

export default {
    name: 'Home',
    components: {
        'citr-paper': Paper
    },
    props: [ 'papers', 'selected' ],
    data() {
        return {
            input: null
        }
    },
    methods: {
        fetchPaper() {
            const _ = this

            ncbi.pubmed.summary(_.$data.input).then(p => {
                _.$emit('add-paper', p)
            })
        },
        changeSelection(paper) {
            this.$emit('change-selection', paper)
        }
    }
}
</script>

<style lang="scss">
.citr-add-bar {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.is-selected {
    background: rgb(235, 235, 235);
}
</style>
