<template>
    <div class="paper-details">
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    <h3>{{ paper.title }}</h3>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p class="card-subtitle text-muted">
                        {{ paper.authors }}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <multiselect
                        class="taglist"
                        v-model="tags"
                        :options="globalTags"
                        mode="tags"
                        :createTag="true"
                        :searchable="true"
                     />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h4>Abstract</h4>
                    <p>{{ abstract }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <h4>Notes</h4>
                    <textarea class="form-control" id="notes" rows="7" v-model="notes"></textarea>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <button class="btn btn-success savebutton" @click.prevent="save">Save</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import MultiSelect from '@vueform/multiselect'
import ncbi from 'node-ncbi'

export default {
    components: {
        'multiselect': MultiSelect
    },
    props: [ 'paper', 'globalTags' ],
    data() {
        return {
            tags: [],
            abstract: '',
            notes: ''
        }
    },
    beforeMount() {
        if (this.paper && this.paper.citr && this.paper.citr.abstract) {
            this.$data.abstract = this.paper.citr.abstract
            this.$data.tags.push(...this.paper.citr.tags)
            this.$data.notes = this.paper.citr.notes
            
        } else {
            const _ = this
            ncbi.pubmed.abstract(this.paper.pmid).then(abstract => {
                _.$data.abstract = abstract
            })
        }
    },
    watch: {
        paper(newValue) {
            if (newValue.citr) {
                this.$data.abstract = this.paper.citr.abstract
                //this.$data.tags.length = 0
                //this.$data.tags.push(...this.paper.citr.tags)
                this.$data.tags = this.paper.citr.tags
                this.$data.notes = this.paper.citr.notes
            } else {
                const _ = this
                ncbi.pubmed.abstract(this.paper.pmid).then(abstract => {
                    _.$data.abstract = abstract
                })
                this.$data.tags = []
                this.$data.notes = ''
            }
        }
    },
    methods: {
        save() {
            this.$emit('paper-details-changed', this.paper, this.$data.notes, this.$data.tags, this.$data.abstract)
        }
    }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
.taglist {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.savebutton {
    margin-top: 1rem;
}
</style>
