<template>
    <navigation-bar class="navbar" :papers="papers" />

    <div :class="{ 'side-by-side': selected }">
        <div class="left">
            <router-view 
                :papers="papers" 
                :selected="selected" 
                @add-paper="addPaper" 
                @change-selection="changeSelection" />
        </div>
        <div class="right" v-if="selected">
            <paper-details 
                :paper="papers.find(x => x.pmid == selected)" 
                :globalTags="tags"
                @paper-details-changed="paperDetailsChanged" />
        </div>
    </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar'
import PaperDetails from '@/components/PaperDetails'
import 'bootstrap'

export default {
    name: 'App',
    components: {
        'navigation-bar': NavigationBar,
        'paper-details': PaperDetails
    },
    data() {
        return {
            papers: [],
            tags: [],
            selected: 16601835
        }
    },
    methods: {
        addPaper(paper) {
            this.$data.papers.push(paper)
        },
        changeSelection(paper) {
            if (paper && paper.pmid) {
                if (this.selected == paper.pmid) {
                    this.selected = null
                } else {
                    this.selected = paper.pmid
                }
            }
        },
        paperDetailsChanged(paper, notes, tags, abstract) {
            const p = this.$data.papers.find(x => paper.pmid == x.pmid)

            if (p) {
                if (!p.citr) {
                    p.citr = {}
                }

                p.citr.tags = tags
                p.citr.abstract = abstract
                p.citr.notes = notes
            }

            this.getAllTags()
        },
        getAllTags() {
            const tags = this.$data.papers
                            .map(p => p.citr || {})
                            .map(c => c.tags || [])
                            .reduce((prv, cur) => prv.concat(cur), []) // [].flat()
                            .filter((v, i, a) => a.indexOf(v) === i) // unique

            this.$data.tags = tags
        }
    },
    created() {
        const data = localStorage.getItem('data')
        if (data) {
            this.$data.papers = JSON.parse(data)
        }
        this.getAllTags()
    }
}
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";

.navbar {
    z-index: 101;
}

.side-by-side > .left {
    width: 50%;
    overflow-x: auto;
}

.right {
    width: 50vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    padding-top: 5rem;
}

</style>
