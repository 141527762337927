<template>
<div class="card citr-card">
  <div class="card-body">
    <h5 class="card-title">{{ innerData.title }}</h5>
    <h6 class="card-subtitle mb-2 text-muted">{{ innerData.authors }}</h6>
    <p class="card-text">{{ innerData.notes }}</p>
    <small>{{ innerData.pubDate }}</small> &nbsp; 
    <a :href="'https://www.ncbi.nlm.nih.gov/pubmed/' + innerData.pmid" class="card-link">Pubmed</a>
  </div>
</div>
</template>

<script>
export default {
    name: 'Paper',
    props: ['paper'],
    data() {
        return {
            innerData: this.paper
        }
    }
}
</script>

<style lang="scss">
.citr-card {
    display: inline-flex;
    width:24rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
}
</style>
